var PeerRelay = require('o2services-peer-relay')
var Debug = require('debug')

Debug.enable('o2services-peer-relay:*')

module.exports.start = function (opts) {
    var socket = new PeerRelay.Socket(opts)

    socket.on('error', function (err) {
        console.log(`Socket error: `, err)
    })

    socket.on('close', function () {
        console.log('Socket has disconnected')
    })

    return socket
}

module.exports.bufToStr = function (buf) {
    return buf.toString('hex')
}

module.exports.strToBuf = function (str) {
    return new Buffer(str, 'hex')
}
